body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera  remove spinner */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.chat-canvas {
  padding: 15px;
  flex-grow: 1;
}

.chat-bg {
  background: url("https://storage.googleapis.com/bakestreet-f55a0.appspot.com/images/bg/connect_bg.png");
  background-repeat: repeat;
}

.react-tel-input input.phone {
  width: 100%;
}

.ql-editor { min-height: 5rem; max-height: 10rem }

.ql-container { font-size: 1rem; }
.q-container > p { padding: 0; margin: 0 }
.q-container > ol { margin: 0 }
.q-container > ul { margin: 0 }